import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Image from 'src/components/image/index'
import Link from 'src/components/link/index'
import Information from 'src/components/information/index'
import Features from 'src/components/features/index'
import Slider from 'src/components/news/slider'
import Facility from 'src/components/facility/index'
import I_AM_THE_FIT from 'src/components/iatf/index'
import BodyStyling from 'src/components/bodystyling/index'
import GoogleMap from 'src/components/googlemap/index'
import ReadyToGo from 'src/components/readytogo/index'

import styles from '../index.module.scss'

const GymPage = () => {
	return (
		<div>
			<SEO
				title="江古田店"
				description="WE ARE THE FITは江古田駅南口から徒歩1分にある24時間営業のフィットネスジムです。本格マシーンを多数取り揃え、パーソナルトレーニング、ボディメイクプランも承っています。初心者向けエリアもご用意しておりますので、まずはお気軽にご見学にお越しください。"
			/>
			<Header hasBack={false} />

			<Mainview className={styles.mainView}>
				<div className={styles.mainBody}>
					<div className={styles.mainImage}>
						<Image src="/gyms/ekoda/mainview.jpg" />
					</div>
					<div className={styles.mainTitle}>
						<span>WE ARE THE FIT</span>
						<h1>EKODA</h1>
					</div>
				</div>
				<Information gym="ekoda" />
			</Mainview>

			<section className={styles.news}>
				<Title
					h2="NEWS"
					right={
						<Link className={styles.newsLink} to="/news/">
							View All News
						</Link>
					}
				/>
				<div className={styles.newsList}>
					<Slider filter="ekoda" />
				</div>
				<div className={styles.newsAll}>
					<Link to="/news/">View All News</Link>
				</div>
			</section>

			<section className={styles.features}>
				<Title
					h2="FEATURE"
					sub={
						<div className={styles.subtitle}>
							ワークアウトが初めての方でも扱いやすいマシンを集めたエリアと上級者も満足のマシンを揃えたエリア。それぞれ適した環境を備えた空間は、これまでの24時間ジムでは得られなかった体験をお届けします。
						</div>
					}
				/>
				<div className={styles.featuresList}>
					<Features
						items={[
							{
								title: `特徴１：初心者向けエリア`,
								text: `フィットネス初心者でも、ご安心ください。<br />WE ARE THE FITは初心者向けエリアをご用意しておりますので、周りに気兼ねなく安心してご利用いただけます。<br />スタッフによる施設案内・マシーンの使い方の説明・カウンセリング・トレーニングサポートプランを無料で実施。1対1のサポートですので、初めての方でもご心配なく。<br />初回でなくても、不明点・ご質問などあればいつでもスタッフにお声がけください。`,
								image: `/gyms/ekoda/feature_01.jpg`,
							},
							{
								title: `特徴２：上級者向けエリア`,
								text: `最新マシーンを揃えた上級者エリアで、本格的なトレーニングを。<br />トレーニングに慣れてきた方、本格的なボディーメイクのための最新マシーンを揃えた上級者エリアを用意しています。特にフリーウェイトマシンの種類の豊富さには自信があります。ダンベルも最高50kgまでご用意。各種アクセサリーも豊富にご用意しております。`,
								image: `/gyms/ekoda/feature_02.jpg`,
							},
							{
								title: `特徴３：24時間利用可能`,
								text: `WE ARE THE FITは、24時間営業のフィットネスクラブです。<br />スタッフの常駐していない早朝・深夜帯でも、セキュリティカードをかざすだけでいつでも施設を利用できます。しっかりセキュリティ対策もされているので女性の方の深夜帯利用も安心です。`,
								image: `/gyms/ekoda/feature_03.jpg`,
							},
							{
								title: `特徴４：本格マシーン多数設置`,
								text: `世界標準の最新マシーンを多数取り揃えました。かなり自信があります。本格的なマシーンを使ったトレーニングで理想のカラダ作りを。効果的なトレーニングに欠かせないアクセサリーの種類も豊富にご用意。`,
								image: `/gyms/ekoda/feature_04.jpg`,
							},
							{
								title: `特徴５：トレーニングのサポート`,
								text: `WE ARE THE FITにはトレーニングに精通したスタッフが常駐しております（スタッフ在中時間帯）。マシンの使い方を紹介したり、トレーニングアドバイスなどご質問にあわせてサポートいたします。ご入会時にはビギナーズサポートとして無料のサポートプランをご用意。1対1での丁寧なサポートでトレーニングが初めての方にも続けやすい環境を準備してお待ちしております。`,
								image: `/gyms/ekoda/feature_05.jpg`,
							},
						]}
					/>
				</div>
				<div className={styles.services}>
					<Inner>
						<I_AM_THE_FIT />
						<BodyStyling />
					</Inner>
				</div>
			</section>

			<section className={styles.facilities}>
				<Title
					h2="FACILITIES"
					sub={
						<div className={styles.subtitle}>
							トレーニングに慣れている方、初級エリアでは物足りなくなった方向けに、最新マシーンを揃えた上級者エリアを用意しています。特にフリーウェイトマシンの種類の豊富さには自信があります。ダンベルも最高50kgまでご用意。
						</div>
					}
				/>
				<Facility
					items={[
						{
							image: `/gyms/ekoda/facility_01.jpg`,
							caption: `24時間ジムとしては貴重な広々としたラウンジをご用意しております。ネットワーク環境も完備。体組成測定機も設置してあります。バルコニーもご利用頂けますので、トレーニング後の休憩にご活用ください。`,
						},
						{
							image: `/gyms/ekoda/facility_02.jpg`,
							caption: `ワークアウト初心者でも扱いやすいマシンを多数取り揃えております。スタッフカウンターもございますので、ご質問などあればお気軽にお声掛け下さい。`,
						},
						{
							image: `/gyms/ekoda/facility_03.jpg`,
							caption: `ランニングマシン、バイクと有酸素トレーニングに最適なマシンを揃えております。台数も多くございますので、待ち時間もあまり気にせずご利用いただけます。`,
						},
						{
							image: `/gyms/ekoda/facility_04.jpg`,
							caption: `部位ごとに必要なマシンをご用意。人気のマシンは複数台ご用意しております。スタッフのサポートが必要な場合はお気軽にお申し付けください。`,
						},
						{
							image: `/gyms/ekoda/facility_05.jpg`,
							caption: `最大5名まで利用可能なストレッチエリアをご用意。アクセサリも豊富にございますので、身体のコンディションを整えるのに最適です。`,
						},
						{
							image: `/gyms/ekoda/facility_06.jpg`,
							caption: `ちょっとした休憩スペースもご用意しております。水分補給やプロテイン接種などに最適です。流しも設置してありますので、シェイカーを洗ったり、飲み残しのドリンクを捨てたりとご活用いただけます。自動販売機も設置がございます。`,
						},
						{
							image: `/gyms/ekoda/facility_07.jpg`,
							caption: `身体を確認、フォームを確認するための鏡を全面に設置。あらゆる角度からワークアウトを見つめる事が可能です。`,
						},
						{
							image: `/gyms/ekoda/facility_08.jpg`,
							caption: `部位ごとに必要なマシンを設置。人気の高いパワーラックエリートは3台と都内24時間ジムでは設置の珍しいマシンなど、マシンへの情熱はご覧いただければご理解いただけるはずです。`,
						},
						{
							image: `/gyms/ekoda/facility_09.jpg`,
							caption: `マシンの設置台数は都内の24時間ジムでは考えられないほど豊富にご用意。皆様のワークアウトに必要な1台がきっと見つかるはずです。`,
						},
						{
							image: `/gyms/ekoda/facility_10.jpg`,
							caption: `ダンベルは50kgまでご用意。ダンベルトレーニングのエリアも広く、ダンベルミットもございますので、高重量でのトレーニングも安心してご利用頂けます。`,
						},
					]}
				/>
			</section>

			<section className={styles.access}>
				<Inner>
					<div className={styles.accessGrid}>
						<div className={styles.accessTitle}>
							<h2>ACCESS</h2>
						</div>
						<div className={styles.accessData}>
							<div className={styles.accessName}>WE ARE THE FIT EKODA</div>
							<div className={styles.accessAddress}>
								〒176-0005 東京都練馬区旭丘1-71-6
								<br />
								プロパティ江古田3階(受付）
							</div>
							<div className={styles.accessTel}>TEL：03-5906-5777</div>
							<div className={styles.accessOther}>
								24時間営業<small>（スタッフ常駐時間 11:00～15:00、16:00～20:00）</small>
							</div>
						</div>
						<div className={styles.accessMap}>
							<div className={styles.accessPlacer}>
								<div>
									<GoogleMap center={{ lat: 35.7369226532578, lng: 139.6739805994977 }} />
								</div>
							</div>
						</div>
					</div>
				</Inner>
			</section>

			<ReadyToGo />
		</div>
	)
}

export default GymPage
