import React from 'react'

import Link from 'src/components/link/index'

import styles from './index.module.scss'


const BodyStyling = () => {

    return (
        <Link to="https://body-s.jp/ekoda/" className={styles.bodystyling}>
            <div className={styles.placer}></div>
            <div className={styles.data}>
                <div className={styles.text}>
                    <div className={styles.title}>Body Styling</div>
                    <div className={styles.esthetic}>セルフエステ</div>
                </div>
            </div>
        </Link>
    )
}

export default BodyStyling